import { inject, observer } from "mobx-react";
import React, { useCallback } from "react";
import { injectIntl, WrappedComponentProps } from "react-intl";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import "./styles.scss";
import ModalCloseButton from "library/components/modal/modal-close-button";
import Modal from "library/components/modal";
import TailwindButton from "library/components/_tailwind/button";
import TailwindBox from "library/components/_tailwind/box";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface Props {
  broadcastStore?: BroadcastStore;
  languageStore?: LanguageStore;
}

const BroadcastConfirmStartTippingShowModal: React.FunctionComponent<
  Props & WrappedComponentProps
> = ({ broadcastStore, languageStore }) => {
  const { intl } = languageStore!;
  const {
    startGoalDown,
    shouldShowStartTippingShowConfirmation,
    setShouldShowStartTippingShowConfirmation,
  } = broadcastStore!;

  const onClickNo = useCallback(() => {
    setShouldShowStartTippingShowConfirmation(false);
  }, []);

  const onClickYes = useCallback(async () => {
    setShouldShowStartTippingShowConfirmation(false);
    await startGoalDown();
  }, [startGoalDown]);

  return (
    <Modal open={shouldShowStartTippingShowConfirmation || false}>
      <TailwindBox
        className={["BroadcastShowTypeModal"]}
        backgroundColor={"bg-primary-bg-color"}>
        <ModalCloseButton closeModal={() => onClickNo()} />
        <p>
          {intl.formatMessage({
            id: "broadcast-show-type-control.tipping.confirm-start-title",
            defaultMessage: `You have not reached your Goal yet, are you sure you want to Start Goal Show?`,
          })}
        </p>
        <div className='agree-or-cancel-tipping'>
          <TailwindButton
            onClick={onClickNo}
            // backgroundColor={"bg-blue-400"}
            fullWidth={false}
            rounded={false}
            justifyContent={"justify-center"}
            margin={["ml-3"]}
            borderColor={"border-pink-300"}
            borderWidth={["border-2"]}
            borderStyle={"border-solid"}
            width={"w-32"}>
            {intl.formatMessage({
              id: "broadcast-show-type-control.tipping.confirm-start-show-no",
              defaultMessage: "NO",
            })}
          </TailwindButton>
          <TailwindButton
            onClick={onClickYes}
            fullWidth={false}
            backgroundColor={"bg-pink-300"}
            rounded={false}
            justifyContent={"justify-center"}
            margin={["ml-3"]}
            width={"w-56"}>
            {intl.formatMessage({
              id: "broadcast-show-type-control.tipping.confirm-start-show-yes",
              defaultMessage: "START NOW",
            })}
          </TailwindButton>
        </div>
      </TailwindBox>
    </Modal>
  );
};

export default injectIntl(
  inject(
    "broadcastStore",
    "languageStore"
  )(observer(BroadcastConfirmStartTippingShowModal))
);
