import React, { useCallback, useEffect, useMemo, useState } from "react";
import PricingStore from "../_stores/pricing/PricingStore";
import { inject, observer } from "mobx-react";
import BroadcastStore from "../_stores/broadcast/BroadcastStore";
import TailwindInput from "library/components/_tailwind/input";
import { TailwindInputType } from "library/components/_tailwind/input-base";
import { CustomIconName } from "library/components/_tailwind/icon/icons/enums";
import TailwindFlex from "library/components/_tailwind/flex";
import TailwindButton from "library/components/_tailwind/button";
import TailwindTranslatedText from "library/components/_tailwind/translated-text";
import TailwindIcon from "library/components/_tailwind/icon";
import ValidationStore from "library/core/stores/validation/ValidationStore";
import {
  BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES,
  BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE,
  BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE,
} from "../_stores/pricing/consts";
import TailwindTooltip from "library/components/_tailwind/tooltip";
import TailwindText from "library/components/_tailwind/text";
import TailwindBox from "library/components/_tailwind/box";
import { IShowType, ShowTab } from "common/broadcast/_stores/broadcast/enums";
import BroadcastTippingControlActionButton from "common/broadcast/broadcast-control/action-button";
import { ModelPricingResponseChatSettingsTipping } from "common/broadcast/_stores/pricing/types";
import LanguageStore from "library/core/stores/language/LanguageStore";

interface Props {
  pricingStore?: PricingStore;
  broadcastStore?: BroadcastStore;
  validationStore?: ValidationStore;
  languageStore?: LanguageStore;
}

const BroadcastTippingSettings: React.FunctionComponent<Props> = ({
  pricingStore,
  broadcastStore,
  languageStore,
}) => {
  const {
    showTippingSum,
    isCurtainDropped,
    isStartingOrStoppingStream,
    isStartingGoalDown,
    isTippingShowStarted,
    tryStartGoalDown,
    currentShowType,
    previousShowType,
    switchToShow,
  } = broadcastStore!;

  const {
    modelProducts,
    submitTippingPrice,
    setTippingPricingFieldError,
    areTippingFieldsValid,
    tippingLateEntryError,
    tippingGoalError,
    tippingEarlyEntryError,
  } = pricingStore!;
  const { intl, language } = languageStore!;

  const [hasSetPrices, setHasSetPrices] = useState<boolean>(true);

  const [goal, setGoal] = useState<number | undefined>(
    modelProducts?.chat_settings?.tipping?.goal ||
      BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE
  );
  const [earlyEntry, setEarlyEntry] = useState<number | undefined>(
    modelProducts?.chat_settings?.tipping?.earlyEntry ||
      BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE
  );
  const [lateEntry, setLateEntry] = useState<number | undefined>(
    modelProducts?.chat_settings?.tipping?.lateEntry ||
      BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE
  );

  const translateKey = (key, locale) => {
    const translations = {
      goal: {
        'en-US': 'Goal',
        'es': 'objetivo'
      },
      earlyEntry: {
        'en-US': 'Early Entry Fee',
        'es': 'tarifa por entrada anticipada'
      },
      lateEntry: {
        'en-US': 'Late Entry Fee',
        'es': 'tarifa por entrada tardía'
      }
    };
  
    return translations[key] ? translations[key][locale] : key;
  };

  useEffect(() => {
    validatePrice("earlyEntry", earlyEntry!);
    validatePrice("lateEntry", lateEntry!);
    validatePrice("goal", goal!);

    return () => {
      setTippingPricingFieldError("early", undefined);
      setTippingPricingFieldError("late", undefined);
      setTippingPricingFieldError("goal", undefined);
    };
  }, []);

  const validatePrice = (
    priceType: keyof ModelPricingResponseChatSettingsTipping,
    value: number
  ) => {
    let error;
    switch (priceType) {
      case "goal": {
        const translatedKey = translateKey("goal", language);
        if (value < BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-over-default-goal-price",
              defaultMessage: "{key} has to be at least {value}",
            },
            {
              value: BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE,
              key: translatedKey,
            }
          );
        } else if (value > BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-less-than",
              defaultMessage: "{key} has to be less than {value}",
            },
            {
              value: BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES,
              key: translatedKey,
            }
          );
        } else if (earlyEntry && value < +earlyEntry) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-over",
              defaultMessage: "{key} can't be less than Early Entry Fee {value}",
            },
            {
              value: `${earlyEntry}`,
              key: translatedKey,
            }
          );
        }
        setTippingPricingFieldError("goal", error);
        break;
      }
      case "earlyEntry": {
        const translatedKey = translateKey("earlyEntry", language);
        if (value < BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-over-early-entry",
              defaultMessage: "{key} has to be at least {value}",
            },
            {
              value: BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE,
              key: translatedKey,
            }
          );
        } else if (value > BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES) {          
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-less-than-early-entry",
              defaultMessage: "{key} can't be more than {value}",
            },
            {
              value: BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES,
              key: translatedKey,
            }
          );
        } else if (goal && value > +goal) {
          const translatedGoalKey = translateKey("goal", language);
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-less-than-early-entry",
              defaultMessage: "{key} can't be more than {value}",
            },
            {
              value: `${translatedGoalKey} ${goal}`,
              key: translatedKey,
            }
          );
        }
        setTippingPricingFieldError("early", error);
        break;
      }
      case "lateEntry": {
        const translatedKey = translateKey("lateEntry", language);
        if (value < BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-over-late-entry-fee",
              defaultMessage: "{key} has to be at least {value}",
            },
            {
              value: BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE,
              key: translatedKey,
            }
          );
        } else if (value > BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES) {
          error = intl.formatMessage(
            {
              id: "broadcast-show-type-control.tipping.has-to-be-less-than",
              defaultMessage: "{key} has to be less than {value}",
            },
            {
              value: BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES,
              key: translatedKey,
            }
          );
        }
  
        setTippingPricingFieldError("late", error);
        break;
      }
    }
  };

  const formatEntryPrice = (
    currentGoal: number | undefined,
    savedGoal: number | undefined
  ) => {
    let val: number | string | undefined =
      goal !== undefined ? currentGoal : savedGoal;
    val = val?.toString();
    return val && val.length > 0 && val[0] === "0" ? val.substring(1) : val;
  };

  const onChangeGoal = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _value = +event.target.value as unknown as number;
      if (_value <= BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES) {
        setGoal(_value);
        setHasSetPrices(false);
        validatePrice("goal", _value);
      }
    },
    [validatePrice]
  );

  const onChangeEarlyEntry = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _value = +event.target.value as unknown as number;
      if (_value <= BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES) {
        setEarlyEntry(_value);
        setHasSetPrices(false);
        validatePrice("earlyEntry", _value);
      }
    },
    [validatePrice]
  );

  const onChangeLateEntry = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const _value = +event.target.value as unknown as number;
      if (_value <= BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES) {
        setLateEntry(_value);
        setHasSetPrices(false);
        validatePrice("lateEntry", _value);
      }
    },
    [validatePrice]
  );

  const goalValue = useMemo(
    () => formatEntryPrice(goal, modelProducts?.chat_settings?.tipping?.goal),
    [goal, modelProducts]
  );

  const earlyEntryValue = useMemo(
    () =>
      formatEntryPrice(
        earlyEntry,
        modelProducts?.chat_settings?.tipping?.earlyEntry
      ),
    [earlyEntry, modelProducts]
  );

  const lateEntryValue = useMemo(
    () =>
      formatEntryPrice(
        lateEntry,
        modelProducts?.chat_settings?.tipping?.lateEntry
      ),
    [lateEntry, modelProducts]
  );

  const submitTippingPrices = useCallback(() => {
    submitTippingPrice({
      goal: +goal!,
      earlyEntry: +earlyEntry!,
      lateEntry: +lateEntry!,
    });
  }, [goal, earlyEntry, lateEntry]);

  const onClickSubmitTippingPrices = useCallback(() => {
    if (areTippingFieldsValid) {
      submitTippingPrices();
      setHasSetPrices(true);
    }
  }, [areTippingFieldsValid, submitTippingPrices]);

  const onClickStartNow = useCallback(async () => {
    await tryStartGoalDown();
  }, [tryStartGoalDown]);

  const areInputsEnabled = useMemo(() => {
    return !isTippingShowStarted;
  }, [isTippingShowStarted]);

  useEffect(() => {
    if (
      (previousShowType === IShowType.TIPPING ||
        previousShowType === IShowType.CURTAIN_DROPPED) &&
      currentShowType !== IShowType.TIPPING &&
      currentShowType !== IShowType.CURTAIN_DROPPED
    ) {
      setHasSetPrices(false);
    } else if (
      previousShowType !== IShowType.TIPPING &&
      previousShowType !== IShowType.CURTAIN_DROPPED &&
      (currentShowType === IShowType.TIPPING ||
        currentShowType === IShowType.CURTAIN_DROPPED)
    ) {
      setHasSetPrices(true);
      setEarlyEntry(
        modelProducts?.chat_settings?.tipping?.earlyEntry ||
          BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE
      );
      setLateEntry(
        modelProducts?.chat_settings?.tipping?.lateEntry ||
          BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE
      );
      setGoal(
        modelProducts?.chat_settings?.tipping?.goal ||
          BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE
      );
    }
  }, [currentShowType, modelProducts]);

  return (
    <>
      <TailwindFlex width={"w-full"} flexDirection={"flex-col"}>
        <TailwindFlex width={"w-full"} flexDirection={"flex-col"}>
          <TailwindFlex
            alignItems={"items-center"}
            justifyContent={"justify-between"}
            margin={["mb-4"]}>
            <TailwindFlex padding={["pr-5"]}>
              <TailwindText
                width={"w-auto"}
                fontWeight={"font-bold"}
                textColor={"text-gray-600"}
                margin={["mr-1"]}
                lineHeight="leading-none">
                {intl.formatMessage({
                  id: "broadcast-show-type-control.tipping.detail.tip-goal.title",
                  defaultMessage: "Goal",
                })}

                <TailwindTooltip
                  content={intl.formatMessage({
                    id: "broadcast-show-type-control.tipping.detail.number-of-tokens-you-want-collect",
                    defaultMessage:
                      "This is the number of tokens you want to collect before starting the Hidden Goal Show.",
                  })}>
                  <TailwindBox margin={["ml-1"]} display={"inline-block"}>
                    <TailwindIcon
                      style={{ verticalAlign: "-5px" }}
                      textColor={"text-gray-400"}
                      name={CustomIconName["question-mark"]}
                    />
                  </TailwindBox>
                </TailwindTooltip>
                 
              </TailwindText>
            </TailwindFlex>
            <TailwindInput
              data-testid={"tipping-goal-input"}
              required
              rounded={false}
              width={"w-full"}
              boxProps={{ height: "h-10" }}
              backgroundColor={"bg-transparent"}
              borderColor={"border-gray-300"}
              borderWidth={["border-2"]}
              onChange={onChangeGoal}
              disabled={!areInputsEnabled}
              type={TailwindInputType.text}
              value={(goalValue === undefined || goalValue === null
                ? BROADCASTER_DEFAULT_MIN_TIPPING_SHOW_GOAL_PRICE
                : goalValue
              ).toString()}
              error={tippingGoalError}
              rightIconProps={{
                name: CustomIconName.token,
                textColor: "text-yellow-500",
              }}
              max={BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES}
            />
          </TailwindFlex>
          <TailwindFlex
            alignItems={"items-center"}
            justifyContent={"justify-between"}
            margin={["mb-4"]}>
            <TailwindFlex padding={["pr-5"]}>
              <TailwindText
                width={"w-auto"}
                fontWeight={"font-bold"}
                textColor={"text-gray-600"}
                margin={["mr-1"]}
                lineHeight="leading-none">
                
                {intl.formatMessage({
                  id: "broadcast-show-type-control.tipping.detail.early-entry",
                  defaultMessage: "Early Entry Fee",  
                })}

                <TailwindTooltip
                  content={intl.formatMessage({
                    id: "broadcast-show-type-control.tipping.detail.what-is-early-entry",
                    defaultMessage:
                      "This is the number of tokens a single member has to pay to join the Hidden Goal Show when it starts.",
                  })}>
                  <TailwindBox margin={["ml-1"]} display={"inline-block"}>
                    <TailwindIcon
                      style={{ verticalAlign: "-5px" }}
                      textColor={"text-gray-400"}
                      name={CustomIconName["question-mark"]}
                    />
                  </TailwindBox>
                </TailwindTooltip>                 
              </TailwindText>
            </TailwindFlex>
            <TailwindInput
              data-testid={"tipping-early-entry-input"}
              required
              rounded={false}
              width={"w-full"}
              boxProps={{ height: "h-10" }}
              backgroundColor={"bg-transparent"}
              borderColor={"border-gray-300"}
              borderWidth={["border-2"]}
              onChange={onChangeEarlyEntry}
              disabled={!areInputsEnabled}
              type={TailwindInputType.text}
              value={(earlyEntryValue === undefined || earlyEntryValue === null
                ? BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE
                : earlyEntryValue
              ).toString()}
              error={tippingEarlyEntryError}
              rightIconProps={{
                name: CustomIconName.token,
                textColor: "text-yellow-500",
              }}
              max={BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES}
            />
          </TailwindFlex>
          <TailwindFlex
            alignItems={"items-center"}
            justifyContent={"justify-between"}
            margin={["mb-4"]}>

            <TailwindFlex padding={["pr-5"]}>
              <TailwindText
                width={"w-auto"}
                fontWeight={"font-bold"}
                textColor={"text-gray-600"}
                margin={["mr-1"]}
                lineHeight="leading-none">
                
                {intl.formatMessage({
                  id: "broadcast-show-type-control.tipping.detail.late-entry",
                  defaultMessage: "Late Entry Fee",
                })}
                
                <TailwindTooltip
                  content={intl.formatMessage({
                    id: "broadcast-show-type-control.tipping.detail.what-is-late-entry",
                    defaultMessage:
                      "This is the number of tokens a single member has to pay to join your Hidden Goal Show while it is in progress.",
                  })}>
                  <TailwindBox margin={["ml-1"]} display={"inline-block"}>
                    <TailwindIcon
                      style={{ verticalAlign: "-5px" }}
                      textColor={"text-gray-400"}
                      name={CustomIconName["question-mark"]}
                    />
                  </TailwindBox>
                </TailwindTooltip>
              </TailwindText>
            </TailwindFlex>
            <TailwindInput
              data-testid={"tipping-late-entry-input"}
              required
              rounded={false}
              width={"w-full"}
              boxProps={{ height: "h-10" }}
              backgroundColor={"bg-transparent"}
              borderColor={"border-gray-300"}
              borderWidth={["border-2"]}
              value={(lateEntryValue === undefined || lateEntryValue === null
                ? BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE
                : lateEntryValue
              ).toString()}
              onChange={onChangeLateEntry}
              disabled={!areInputsEnabled}
              type={TailwindInputType.text}
              error={tippingLateEntryError}
              rightIconProps={{
                name: CustomIconName.token,
                textColor: "text-yellow-500",
              }}
              min={BROADCASTER_DEFAULT_MIN_TIPPING_EARLY_LATE_ENTRY_PRICE}
              max={BROADCASTER_DEFAULT_MAX_FOR_TIPPING_PRICES}
            />
          </TailwindFlex>
          <TailwindFlex
            alignItems={"items-center"}
            justifyContent={"justify-between"}>
            <TailwindFlex padding={["pr-5"]} alignItems={"items-center"}></TailwindFlex>
            <TailwindFlex alignItems={"items-center"}>
              <TailwindButton
                data-testid={"tipping-update-prices-button"}
                data-is-enabled={!isStartingOrStoppingStream || !hasSetPrices}
                disabled={isStartingOrStoppingStream || hasSetPrices}
                backgroundColor={isStartingOrStoppingStream || hasSetPrices ? "bg-transparent" : "bg-pink-400"}
                borderColor={isStartingOrStoppingStream || hasSetPrices ? "border-pink-300" : "border-pink-400"}
                borderWidth={["border-2"]}
                justifyContent={"justify-center"}
                disabledProps={
                  isStartingOrStoppingStream || hasSetPrices
                    ? {
                        textColor: "text-pink-300",
                        backgroundColor: "bg-transparent",
                      }
                    : undefined
                }              
                textProps={{
                  textTransform: "capitalize",
                  textColor: isStartingOrStoppingStream || hasSetPrices ? "text-pink-400" : "text-white"
                }}
                onClick={onClickSubmitTippingPrices}>
                {intl.formatMessage({
                  id: "broadcast-show-type-control.tipping.set-goal",
                  defaultMessage: "Update Prices",
                })}
              </TailwindButton>
            </TailwindFlex>
          </TailwindFlex>
        </TailwindFlex>
        <TailwindFlex
          width={"w-full"}
          margin={["mt-6"]}
          flexDirection={"flex-col"}>
          {isTippingShowStarted && (
            <TailwindFlex
              flexDirection={"flex-row"}
              width={"w-auto"}
              alignItems={"items-center"}
              justifyContent={"justify-end"}
              margin={["mb-4"]}>
              <TailwindBox margin={["mr-4"]} fontWeight={"font-bold"}>
                <TailwindTranslatedText
                  textColor='text-main-color'
                  fontWeight={"font-bold"}
                  descriptor={{
                    id: "broadcast-show-type-control.tipping.current-tips",
                    defaultMessage: "Current Tips",
                  }}
                />
                :
              </TailwindBox>
              <TailwindText
                fontSize={"text-lg"}
                fontWeight={"font-bold"}
                textColor={"text-yellow-500"}>
                {showTippingSum}
              </TailwindText>
              <TailwindIcon
                className={"ml-1"}
                fontSize={"text-sm"}
                textColor={"text-yellow-500"}
                name={CustomIconName.token}
              />
            </TailwindFlex>
          )}

          <TailwindFlex justifyContent={"justify-center"} padding={["pl-10"]} gap={"gap-2"}>
            {isCurtainDropped ? (
              <BroadcastTippingControlActionButton
                fullWidth={false}
                fullFillButton={isStartingOrStoppingStream || isTippingShowStarted}
                dataTestId={"tipping-end-goal-show-button"}
                disabled={isStartingOrStoppingStream || !isTippingShowStarted}
                onClick={() => switchToShow(ShowTab.FREE)}
                label={intl.formatMessage({
                  id: "broadcast-show-type-control.tipping.curtain-end",
                  defaultMessage: "End Goal Show",
                })}
              />
            ) : (
              <TailwindFlex justifyContent={"justify-center"}>
                <BroadcastTippingControlActionButton
                  fullWidth={false}
                  fullFillButton={isStartingOrStoppingStream || isTippingShowStarted}
                  dataTestId={"tipping-start-goal-show-button"}
                  disabled={isStartingOrStoppingStream || !isTippingShowStarted || isStartingGoalDown}
                  onClick={onClickStartNow}
                  label={intl.formatMessage({
                    id: "broadcast-show-type-control.tipping.curtain",
                    defaultMessage: "Start Hidden Goal Show",
                  })}
                />
                <TailwindTooltip
                  content={intl.formatMessage({
                    id: "broadcast-show-type-control.tipping.tool-tip",
                    defaultMessage:
                      "Start your Hidden Goal Show once the set goal has been reached. You can start earlier, but we recommend waiting until the goal has been reached.",
                  })}>
                  <TailwindFlex
                    width={"w-auto"}
                    alignItems={"items-center"}
                    margin={["ml-2"]}>
                    <TailwindIcon
                      textColor={"text-gray-400"}
                      name={CustomIconName["question-mark"]}
                    />
                  </TailwindFlex>
                </TailwindTooltip>
              </TailwindFlex>
            )}
          </TailwindFlex>
        </TailwindFlex>
      </TailwindFlex>
    </>
  );
};

export default inject(
  "pricingStore",
  "broadcastStore",
  "chatStore",
  "validationStore",
  "languageStore"
)(observer(BroadcastTippingSettings));
